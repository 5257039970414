@import "@assets/style/breakpoints";

.form_container {
  min-height: calc(100vh - 23vh);
  padding-top: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include mobile_smallest_query {
    margin-top: 0 !important;
    padding-top: 62px;
    display: block;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  @include mobile_media_query {
    display: block;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .progress_bar_container {
    position: relative;
    width: 925px;
    @include tablet_media_query {
      width: 90vw;
    }
    @include mobile_media_query {
      position: relative;
      top: 0px;
      padding-bottom: 24px;
      display: block;
      width: 90vw;
    }
    @include mobile_smallest_query {
      padding-bottom: 0;
    }
    .progress_bar_title {
      position: absolute;
      top: -35px;
      left: 0;
      color: var(--grey900);
      letter-spacing: 0.18em;
      text-transform: capitalize;
    }
    ion-progress-bar {
      height: 8px;
      border-radius: 50px;
      --progress-background: var(--black);
      --buffer-background: var(--grey100);
    }
  }
  .title-container {
    width: calc(100% - 20%);
    padding-top: 2%;
    margin: 0 auto;
    @include desktop_md_and_tablet_query {
      padding-top: 0;
    }
    @include mobile_media_query {
      padding-top: 0.5vh;
      padding-left: 24px;
      padding-right: 24px;
    }
    @include mobile_smallest_query {
      width: unset;
      padding-top: 0;
      padding-left: 16px;
      padding-right: 16px;
    }

    .title {
      font-size: 64px;
      font-family: "Poppins", sans-serif;
      color: var(--grey900);
      font-weight: 500;
      font-size: 60px;
      line-height: 70px;
      letter-spacing: 0.2px;
      text-transform: capitalize;
      display: block;

      @include mobile_media_query {
        font-size: 32px;
        line-height: 40px;
        display: block;
        margin-top: 10px;
        margin-bottom: 26px;
      }
      @include mobile_smallest_query {
        font-size: 28px;
        line-height: 38px;
        margin-top: 35px;
      }
    }
  }
}

.workContainer {
  padding: 0 24px;
}

.footer_wrapper {
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  @include mobile_media_query {
    position: unset;
    margin-top: 50px;
  }
  .footer_container {
    border-top: 1px solid var(--grey300);
    color: var(--primary-light);
    padding: 30px 0px;
    box-shadow: 0px 0px 0px !important;
  }
}
.navigation_container {
  @include mobile_media_query {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
  }
}
