@import "@assets/style/breakpoints";

.btn {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0;
  overflow: auto;

  &:hover {
    --border-radius: 3px;
  }

  span {
    font-size: 16px;
    letter-spacing: 0;
    font-weight: normal;
  }
}

.disabled {
  opacity: 0.7;
}

.btn.form_button,
.btn.form_button.button {
  font-family: "Inter", sans-serif;
  width: 359px;
  height: 71px;
  --background: transparent;
  border: 1px solid var(--black);
  color: var(--black);

  &:hover {
    --background: var(--white);
    color: var(--grey900);
    border: 1px solid var(--black);
  }

  span {
    font-size: 32px;
  }

  @include mobile_media_query {
    width: 330px;
  }
}

.btn.navigation_disabled,
.btn.navigation_disabled.button,
.btn.navigation.outline,
.btn.navigation.outline.button {
  width: 220px;
  font-family: "Inter", sans-serif;
  height: 48px;
  --background: #000;
  border-radius: 3px;

  &:hover {
    --background-hover: transparent;
  }

  span {
    font-size: 18px;
  }

  @include mobile_media_query {
    width: 160px !important;
  }
}

.btn.navigation,
.btn.navigation.button {
  width: 220px;
  font-family: "Inter", sans-serif;
  height: 48px;
  --background: var(--black);
  color: var(--white);

  &:hover {
    --background-hover: transparent;
  }
  
  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }

  @include mobile_media_query {
    width: 160px;
  }
}
.btn.navigation.outline,
  .btn.navigation.button.outline {
    width: 220px;
    font-family: "Inter", sans-serif;
    height: 48px;
    --background: transparent;
    border: 1px solid var(--black);
    color: var(--black);
  }

.navigation_main_container {
  @include mobile_media_query {
    width: 100%;
    height: 120px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: var(--white);
    box-shadow: var(--navigation-main-container-shadow);
    z-index: 999;
  }
  @include mobile_smallest_query {
    height: 90px;
  }
}

.navigation_container {
  display: flex;
  width: 484px;
  margin-top: 5%;
  margin-bottom: 2%;
  justify-content: space-between;
  @include mobile_media_query {
    margin-top: 0;
  }
}

.selectedButton {
  --background: var(--black) !important;
  color: var(--white) !important;
  border:unset !important;
}
