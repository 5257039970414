@import "@assets/style/breakpoints.scss";
.step-contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-container {
    @include mobile_media_query {
      padding-left: 24px;
      padding-right: 24px;
    }
    .title-text {
      color: var(--grey900);
      font-style: normal;
      font-weight: 500;
      font-size: 60px;
      line-height: 108%;
      letter-spacing: 0.01em;
      text-transform: capitalize;

      @include mobile_media_query {
        display: block;
        font-size: 32px;
        margin-top: 0px;
        line-height: 48px;
        margin-top: 26px;
      }
      @include mobile_smallest_query {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }

  .input-container {
    width: 750px;
    margin-top: 76px;
    @include mobile_media_query {
      margin-top: 32px;
      margin-bottom: 0;
      padding: 0 24px;
      width: 100%;
    }

    .inputs-col {
      margin-bottom: 16px;
      ion-input {
        --padding-end: 0px;
        --padding-start: 0px;
        text-indent: 0px;
        color: var(--grey900) !important;
      }

      .input_error {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        @include mobile_media_query {
          margin-left: 0;
        }
        @include mobile_smallest_query {
          margin-left: 0;
        }
      }

      @include mobile_media_query {
        margin-bottom: 30px;
      }

      &.full_col {
        padding: 0 !important;
      }

      &:first-child {
        padding-right: 24px;
      }

      &:last-child {
        padding-left: 24px;
      }

      @include mobile_media_query {
        &:first-child {
          padding-right: 0;
        }
        &:last-child {
          padding-left: 0;
        }
      }
    }

    .contact-type-buttons-row {
      justify-content: space-between;

      .contact-type-button {
        height: 58px;
        width: 100%;

        span {
          @include mobile_media_query {
            font-size: 20px !important;
          }
        }
      }
    }

    .contact-type-label-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .contact-type-label {
        color: var(--grey900);
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
        margin-right: 24px;
      }
    }

    .input-wrapper {
      max-width: 350px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: var(--black);
      color: var(--grey900) !important;
      text-align: left;

      .input-block {
        margin-bottom: 4px;
      }

      @include mobile_media_query {
        width: 100%;
      }
      .label {
        color: var(--grey900);
      }

      input::placeholder {
        --placeholder-color: var(--grey800) !important;
        color: var(--grey800) !important;
      }
    }

    .phone-input {
      padding: 0;
      margin: 0;
      .phone-number-input {
        background: unset;
        color: var(--grey900);
        border: 0;
        border-bottom: 1px solid var(--black);
        border-radius: 0;
        padding: 0;
        min-height: 47px;
        margin-bottom: 4px;
      }
      .input-block-fullwidth {
        height: unset;
        --height: unset;
      }

      .country-search-wrapper {
        @include mobile_media_query {
          z-index: 99999;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      width: 220px;
      margin-inline: 24px;
    }
  }
}

#contact_details_form {
  @include mobile_media_query {
    scroll-margin-top: 65px;
  }
}
