@import "@assets/style/breakpoints.scss";

.footer_container {
  display: flex;
  justify-content: space-between;
  padding: 50px 80px;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.12);

  @include mobile_media_query {
    padding: 22px 0px !important;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .footer_list {
    display: flex;
    gap: 40px;
    ion-text {
      letter-spacing: 0.01em;
      color:var(--grey300);
    }
  }
  .copyright_text{
    color: var(--grey300);
  }
}
