.emailSent {
  .emailSentContainer {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 78vh;

    .emailIcon {
      img {
        filter: invert(100%) sepia(10%) saturate(7470%) hue-rotate(291deg) brightness(108%)
          contrast(117%);
        height: 50px;
      }
    }

    .emailSentTitle {
      font-weight: 800;
      font-size: 44px;
    }

    .emailSentText {
      font-size: 16px;
      line-height: 25px;

      span {
        font-weight: 600;
        color: var(--primary-dark);
      }

      .correct-email {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .footer_wrapper {
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;

    .footer_container {
      border-top: 1px solid var(--grey300);
      color: var(--primary-light);
      padding: 30px 0px;
      box-shadow: 0px 0px 0px !important;
    }
  }
}
