@import "@assets/style/breakpoints.scss";

.address {
  @include mobile_media_query {
    position: relative;
  }

  .title-container {
    margin-bottom: 80px !important;
    @include mobile_smallest_query {
      margin-bottom: 65px !important;
    }

    .title-text {
      color: #000;
      font-style: normal;
      font-weight: 500;
      font-size: 60px;
      line-height: 81px;
      text-transform: capitalize;
      letter-spacing: 0.01em !important;

      @include mobile_media_query {
        font-size: 32px !important;
        line-height: 48px !important;
      }
      @include mobile_smallest_query {
        font-size: 30px !important;
        line-height: 48px !important;
      }
    }
  }

  .addressOutsideAlertContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile_smallest_query {
      margin-bottom: 0;
      margin-top: 0;
      display: block;
    }
  }

  .addressContainer {
    background: var(--white);
    border: 1px solid var(--grey400);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: 600px;
    border-radius: 8px;

    @include mobile_media_query {
      width: calc(100vw - 50px);
      margin-bottom: 80px;
      margin-top: 30px;
    }

    ion-input {
      height: 48px;
    }

    .label_wrapper_container {
      width: 100%;
      padding: 0 10px 0 10px;
      margin: 2px 0;
    }

    .label_wrapper {
      display: flex;
      align-items: center;

      &-left-section {
        width: 8%;
        display: flex;
        justify-content: flex-start;

        ion-img {
          transform: rotate(90deg);
        }
      }

      &-right-section {
        width: 92%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
  }

  .addressInput {
    --placeholder-color: rgba(68, 68, 68, 1);
    --placeholder-opacity: 1;
    letter-spacing: 0.01em;
    color: #444444;
    font-size: 18px;
    text-align: left;
    text-overflow: ellipsis;
    text-indent: 8px;
    --padding-start: 0;
  }

  .dropdown_list {
    position: relative;
    font-family: "Poppins", sans-serif;
    box-shadow: 0px 20px 22px rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    max-height: 340px;
    --padding-start: 10px;
    --padding-bottom: 0;
    --padding-end: 10px;
    --padding-top: 0;
    z-index: 9;
    background: var(--white);
    align-items: center;
    right: 0;
    left: 0;
    width: 600px;
    cursor: pointer;
    margin-top: -3px;

    @include mobile_media_query {
      width: calc(100vw - 50px);
    }

    .dropdown_scroll {
      overflow-y: scroll;
      max-height: 300px;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 5px;
        background: var(--white);
      }
      &::-webkit-scrollbar-thumb {
        padding: 5px 0;
        background: var(--grey800);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        margin: 22px 0 12px;
        background: var(--white);
        margin: 10px 0;
      }
      .item {
        justify-content: space-between;
        .checkBox {
          margin: 0 16px 0 0;
        }
      }
    }

    .sd-clear_btn {
      margin-right: 8px;
    }
  }

  .button-container {
    margin-bottom: 120px;
    margin-top: 120px;

    @include mobile_media_query {
      margin-bottom: 30px;
      margin-top: 30px;
    }
    @include mobile_smallest_query {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .dropdown_list_single_item {
    margin: 0;
    padding: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .address-item {
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 6px 2px 6px;
  }

  .dropdown_list_single_item:active {
    background-color: rgba(224, 240, 250, 1) !important;
  }

  .dropdown_list_single_item:hover {
    background-color: rgba(224, 240, 250, 1) !important;
  }
}
