@import "./assets/style/base";

.app {
  display: flex;
  flex-direction: column;
}
html {
  height: 100%;
  min-height: 100%;
}

.page_body {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;

  .page_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 1vh;

    @include tablet_media_query {
      height: 100%;
      min-height: 100vh;
    }
    @include mobile_media_query {
      height: 100vh;
      padding-top: 0;
    }
    .btn.form_button span,
    .btn.form_button.button span {
      font-size: 26px;
    }
  }
}

input[data-autocompleted] {
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-background-clip: text !important;
}

input:-webkit-autofill{
  -webkit-text-fill-color: var(--grey900) !important;
}
